/**
 * 注册页面
 */
export function getActivityRegisterRoutePath(inviteCode?: string) {
  return inviteCode ? `/register?invitationCode=${inviteCode}` : '/register'
}

/**
 * 绑定账号
 */
export function getBindAccountRoutePath() {
  return '/personal-center/account-security/bind-account'
}

/** 登陆页 */
export function getLoginPath() {
  return '/login'
}

/** 用户安全验证中心 */
export function getPersonalAccountSecurityPath() {
  return '/personal-center/account-security'
}

/** 用户安全验证中心 */
export function getSafetyVerificationPath(searchOriginal?, redirect?) {
  if (searchOriginal || redirect) {
    return `/safety-verification${searchOriginal || `?redirect=${redirect}`}`
  }
  return '/safety-verification'
}

/** 注册认证 */
export function getRegisterVerificationPath(method) {
  return `/register/verification?type=${method}`
}

/** 重置安全项验证 */
export function getSafetyicationPath() {
  return `/safety-items`
}

/** 重置密码 - 人工 */
export function getResetPasswordPath() {
  return '/reset-password'
}

/** 安全项验证 */
export function getRestSafetyicationPath() {
  return `/rest-safety-verification`
}

/** 重置密码 */
export function getRetrieveResetPasswordPath(artificial = false) {
  if (artificial) {
    return '/retrieve/reset-password?artificial=true'
  }
  return `/retrieve/reset-password`
}
